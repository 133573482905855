<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getListPaymentETI"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'PaymentsETI',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'pay_time', text: this.$t('paymentDate'), sortable: false },
        { value: 'platon_id', text: this.$t('paymentId'), sortable: false },
        { value: 'full_name', text: this.$t('fullName'), sortable: false },
        { value: 'course', text: this.$t('course'), sortable: false },
        { value: 'institution', text: this.$t('nameInstitution'), sortable: false },
        { value: 'amount', text: this.$t('amount'), sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: ({ sailor }) => ({ name: 'sailor', params: { id: sailor }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-account-circle-outline',
          tooltip: 'tooltip.goToSailor',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.listPaymentETI,
      isLoading: state => state.statement.isLoading
    })
  },
  methods: {
    ...mapActions(['getListPaymentETI'])
  }
}
</script>
